<template>
  <b-modal
    id="modal-password-reset"
    ref="modal-password-reset"
    centered
    title="Reset Employee Password"
    ok-only
    ok-title="Reset Password"
    hide-footer
  >
    <div>
      <b-overlay
        :show="showEmployeeResetOverlay"
        rounded="sm"
        variant="dark"
      >
        <div>
          <b-form
            @submit="onSubmitPasswordReset"
            @reset="onResetPasswordReset"
          >
            <b-form-group>
              <label for="name">Name:</label>
              <h5 class="secondary-text">
                {{ employee.name }}
              </h5>
            </b-form-group>

            <b-form-group>
              <label for="email">Email:</label>
              <h6 class="secondary-text">
                {{ employee.email }}
              </h6>
            </b-form-group>

            <b-row>
              <b-col>
                <b-form-group>
                  <label for="phone">Phone:</label>
                  <h6 class="secondary-text">
                    {{ employee.phone }}
                  </h6>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group>
                  <label for="password">Create Password</label>
                  <b-form-input
                    id="password"
                    v-model="password"
                    type="password"
                    placeholder="Create Password"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <label
                    for="password_confirmation"
                  >Confirm Password</label>
                  <b-form-input
                    id="password_confirmation"
                    v-model="password_confirmation"
                    type="password"
                    placeholder="Confirm Password"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col
                md="12"
                lg="12"
              >
                <b-button
                  class="mx-1 float-right"
                  type="submit"
                  variant="primary"
                >Submit</b-button>
                <b-button
                  class="mx-0 float-right"
                  type="reset"
                  variant="danger"
                >Reset</b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BModal,
  BForm,
  BOverlay,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { apiUrl } from '@/constants/config'
import store from '@/store'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    BForm,
    BOverlay,
  },
  props: {
    employee: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showEmployeeResetOverlay: false,
      hasError: false,
      password: '',
      password_confirmation: '',
      errors: {
        message: '',
        data: [],
      },
    }
  },
  methods: {
    onSubmitPasswordReset(event) {
      this.showEmployeeResetOverlay = true
      event.preventDefault()
      axios({
        url: `${apiUrl}employee/reset-password/${this.employee.id}`,
        data: {
          password: this.password,
          password_confirmation: this.password_confirmation,
        },
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${store.state.user.currentUser.token}`,
        },
      })
        .then(response => {
          this.$refs['modal-password-reset'].hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Success: ${response.data.message}`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Error: ${error.message}`,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.showEmployeeResetOverlay = false
        })
    },
    onResetPasswordReset(event) {
      event.preventDefault()
      this.password = ''
      this.password_confirmation = ''
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
