<template>
  <b-modal
    id="modal-employee"
    ref="modal-employee"
    centered
    title="Create new employee"
    ok-only
    ok-title="Submit"
    hide-footer
  >
    <div>
      <b-overlay
        :show="showEmployeeOverlay"
        rounded="sm"
        variant="dark"
      >
        <div>
          <!-- ERROR ALERT BOX -->
          <b-row v-if="hasError">
            <b-col cols="12 text-left">
              <div
                class="alert alert-danger alert-dismissible"
                role="alert"
              >
                <h4 class="alert-heading">
                  {{ errors.message }}
                </h4>
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  @click="hasError = !hasError"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div class="alert-body">
                  <div
                    v-for="(item, index) in errors.data"
                    :key="index"
                  >
                    * <small>{{ item.toString() }}</small>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-form
            @submit="onSubmitEmployee"
            @reset="onResetEmployee"
          >
            <b-form-group>
              <label for="name">Name:</label>
              <b-form-input
                id="name"
                v-model="employee.name"
                type="text"
                placeholder="Name"
                required
              />
            </b-form-group>

            <b-form-group>
              <label for="email">Email:</label>
              <b-form-input
                id="email"
                v-model="employee.email"
                type="email"
                placeholder="Email Address"
                required
              />
            </b-form-group>

            <b-row>
              <b-col>
                <b-form-group>
                  <label for="phone">Phone:</label>
                  <b-form-input
                    id="phone"
                    v-model="employee.phone"
                    type="text"
                    placeholder="Phone"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <hr>
            <b-row>
              <b-col
                md="12"
                lg="12"
              >
                <b-button
                  class="mx-1 float-right"
                  type="submit"
                  variant="primary"
                >Submit</b-button>
                <b-button
                  class="mx-0 float-right"
                  type="reset"
                  variant="danger"
                >Reset</b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BOverlay,
} from 'bootstrap-vue'
import { apiUrl } from '@/constants/config'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BOverlay,
  },
  data() {
    return {
      employee: {
        name: '',
        email: '',
        phone: '',
        password: '',
        password_confirmation: '',
      },
      showEmployeeOverlay: false,
      hasError: false,
      errors: {
        message: '',
        data: [],
      },
    }
  },
  methods: {
    onSubmitEmployee(event) {
      this.hasError = false
      this.showEmployeeOverlay = true
      event.preventDefault()

      // post data
      axios({
        url: `${apiUrl}employee`,
        data: this.employee,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${store.state.user.currentUser.token}`,
        },
      })
        .then(response => {
          this.$emit('employee-created')
          this.$refs['modal-employee'].hide()
          this.onResetEmployee(event)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Success: ${response.data.message}!`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.hasError = true
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Error: ${this.parseError(error.response)}`,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.showEmployeeOverlay = false
        })
    },
    onResetEmployee(event) {
      event.preventDefault()
      // Reset our form values
      this.employee.name = ''
      this.employee.email = ''
      this.employee.phone = ''
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
