<template>
  <section>
    <b-row>
      <b-col cols="12">
        <employee-list />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import EmployeeList from '@/components/EmployeeList.vue'

export default {
  components: {
    BRow,
    BCol,
    EmployeeList,
  },
}
</script>

  <style>

  </style>
