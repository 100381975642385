<template>
  <b-modal
    id="modal-edit-employee"
    ref="modal-edit-employee"
    centered
    title="Edit employee details"
    ok-only
    ok-title="Edit employee"
    hide-footer
  >
    <div>
      <b-overlay
        :show="showEmployeeEditOverlay"
        rounded="sm"
        variant="dark"
      >
        <div>
          <b-form
            @submit="onSubmitEditEmployee"
            @reset="onResetEditEmployee"
          >
            <b-form-group>
              <label for="name">Name:</label>
              <b-form-input
                id="name"
                v-model="employee.name"
                type="text"
                placeholder="Name"
              />
            </b-form-group>

            <b-form-group>
              <label for="email">Email:</label>
              <b-form-input
                id="email"
                v-model="employee.email"
                type="email"
                placeholder="Email Address"
              />
            </b-form-group>

            <b-row>
              <b-col>
                <b-form-group>
                  <label for="phone">Phone:</label>
                  <b-form-input
                    id="phone"
                    v-model="employee.phone"
                    type="text"
                    placeholder="Phone"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col
                md="12"
                lg="12"
              >
                <b-button
                  class="mx-1 float-right"
                  type="submit"
                  variant="primary"
                >Submit</b-button>
                <b-button
                  class="mx-0 float-right"
                  type="reset"
                  variant="danger"
                >Reset</b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BModal,
  BForm,
  BOverlay,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { apiUrl } from '@/constants/config'
import store from '@/store'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    BForm,
    BOverlay,
  },
  props: {
    employee: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showEmployeeEditOverlay: false,
    }
  },
  methods: {
    onResetEditEmployee(event) {
      event.preventDefault()
      this.employee.name = ''
      this.employee.email = ''
      this.employee.phone = ''
    },
    onSubmitEditEmployee(event) {
      this.showEmployeeEditOverlay = true
      event.preventDefault()

      // post data
      axios({
        url: `${apiUrl}employee/${this.employee.id}`,
        data: this.employee,
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${store.state.user.currentUser.token}`,
        },
      })
        .then(response => {
          this.$refs['modal-edit-employee'].hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Success: ${response.data.message}`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Error: ${error.message}`,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.showEmployeeEditOverlay = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
