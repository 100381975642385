<template>
  <div>
    <create-employee-form @employee-created="getEmployees" />
    <b-overlay
      :show="show"
      rounded="sm"
      variant="dark"
    >
      <div>
        <b-card>
          <b-row>
            <b-col
              md="12"
              sm="12"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-employee
                variant="outline-primary"
                class="float-right"
              >
                <feather-icon
                  icon="UsersIcon"
                  size="16"
                  class="bg-light-info"
                />
                <span class="align-middle ml-50">Add Employee</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="2"
              sm="4"
              class="my-1"
            >
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="4"
              sm="8"
              class="my-1"
            >
              <b-form-group
                label="Sort"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-75"
                  >
                    <template v-slot:first>
                      <option value="">
                        -- none --
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                    class="w-25"
                  >
                    <option :value="false">
                      Asc
                    </option>
                    <option :value="true">
                      Desc
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              class="my-1"
            >
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-table
                striped
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(name)="data">
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        size="32"
                        :src="data.item.image"
                        :text="avatarText(data.item.name)"
                      />
                    </template>
                    <span class="font-weight-bold d-block text-nowrap">
                      {{ data.item.name }}
                    </span>
                  </b-media>
                </template>

                <template #cell(status)="data">
                  <b-badge
                    v-if="data.item.is_active === 1"
                    :variant="'light-success'"
                  >
                    ACTIVE
                  </b-badge>
                  <b-badge
                    v-else
                    :variant="'light-danger'"
                  >
                    INACTIVE
                  </b-badge>
                </template>

                <template #cell(actions)="data">
                  <div
                    class="text-nowrap"
                    variant="danger"
                  >
                    <b-avatar variant="light-danger">
                      <feather-icon
                        :id="`invoice-row-${data.item.id}-verify-icon`"
                        icon="Trash2Icon"
                        class="cursor-pointer"
                        size="16"
                        @click="deleteEmployee(data.item)"
                      />
                    </b-avatar>
                    <b-tooltip
                      title="Delete Employee"
                      class="cursor-pointer"
                      :target="`invoice-row-${data.item.id}-verify-icon`"
                    />

                    <b-avatar variant="light-info">
                      <feather-icon
                        :id="`edit-row-${data.item.id}-verify-icon`"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-edit-employee
                        icon="EditIcon"
                        class="cursor-pointer"
                        size="16"
                        @click="editEmployee(data.item)"
                      />
                    </b-avatar>
                    <b-tooltip
                      title="Edit employee"
                      class="cursor-pointer"
                      :target="`edit-row-${data.item.id}-verify-icon`"
                    />

                    <b-avatar variant="light-warning">
                      <feather-icon
                        :id="`password-row-${data.item.id}-verify-icon`"
                        icon="KeyIcon"
                        class="cursor-pointer"
                        size="16"
                        @click="resetEmployeePassword(data.item)"
                      />
                    </b-avatar>
                    <b-tooltip
                      title="Reset Password"
                      class="cursor-pointer"
                      :target="`password-row-${data.item.id}-verify-icon`"
                    />
                  </div>
                </template>
              </b-table>
            </b-col>

            <b-col cols="12">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>

          <edit-employee-modal :employee="employee" />

          <employee-password-reset-modal
            ref="password_reset"
            :employee="employee"
          />
        </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BTooltip,
  BMedia,
  BOverlay,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import { apiUrl } from '@/constants/config'
import store from '@/store'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CreateEmployeeForm from '@/components/CreateEmployeeForm.vue'
import EditEmployeeModal from '@/components/EditEmployeeModal.vue'
import EmployeePasswordResetModal from '@/components/EmployeePasswordResetModal.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BTooltip,
    BMedia,
    BOverlay,
    CreateEmployeeForm,
    EditEmployeeModal,
    EmployeePasswordResetModal,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [5, 10, 25],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'name', label: 'Full Name', sortable: true },
        'phone',
        'email',
        { key: 'status', label: 'Status', sortable: true },
        { key: 'actions', label: 'Action' },
      ],
      items: [],
      employee: {
        name: '',
        email: '',
        phone: '',
      },
      show: false,
    }
  },
  setup() {
    return {
      avatarText,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.getEmployees()
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getEmployees() {
      this.show = true
      try {
        axios
          .get(`${apiUrl}employees`, {
            headers: {
              Authorization: `Bearer ${store.state.user.currentUser.token}`,
            },
          })
          .then(response => {
            const { data } = response.data
            this.items = data
            // store.state.user.employeeAdded = false

            // Set the number of rows on b-table
            this.totalRows = data.length
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.show = false
          })
      } catch (e) {
        console.log(e)
      }
    },
    deleteEmployee(acc) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure that you want to delete this employee: (${acc.name})`,
          {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.delete(acc.id)
          }
        })
    },
    delete(id) {
      try {
        axios
          .delete(`${apiUrl}employee/${id}`, {
            headers: {
              'content-type': 'application/json',
              Authorization: `Bearer ${store.state.user.currentUser.token}`,
            },
          })
          .then(() => {
            this.items = this.items.filter(value => value.id !== id)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success: Employee deleted!',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error: ${error.message}`,
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error: Could not initiate request!',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    },

    editEmployee(acc) {
      this.employee = acc
    },

    resetEmployeePassword(employee) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure that you want to reset this employee's password: (${employee.name}).`,
          {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.employee = employee
            this.$refs.password_reset.$refs['modal-password-reset'].show()
          }
        })
    },
  },
}
</script>
